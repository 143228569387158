import { useState } from 'react';
import * as React from 'react';
import Head from 'next/head';
import uniqWith from 'lodash/uniqWith';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import getConfig from 'next/config';
import { useTranslation } from 'next-i18next';
import { usePageMetrics, PageName, useWrappedRouter } from '../../hooks';
import { addDays, differenceInDays, parseISO } from 'date-fns';
import { useAuth } from '@dx-ui/framework-auth-provider';
import type { MapLanguage } from '@dx-ui/framework-uri-builder';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import type { IHotel } from '../../components/HotelInfo/HotelInfo';
import HotelInfo from '../../components/HotelInfo/HotelInfo';
import RoomsRates from '../../components/RoomsRates/RoomsRates';
import ResortFeeDetails from '../../components/ResortFeeDetails/ResortFeeDetails';
import PreviewBody from '../../components/PreviewBody/PreviewBody';
import PreviewHeadline from '../../components/PreviewHeadline/PreviewHeadline';
import { spaRequest } from './CustomGroup.utils';
import { PageStatus } from '../../lib';
import InlineLoader from '../InlineLoader/InlineLoader';
import { useGetEventPageQuery } from '../../generated/react-query';
import type { GetEventPageQuery, GetShopAvailQuery } from '../../generated/types';
import type { EventInfoRatePlans } from '../../hooks/useEventData';
import { useQueryClient } from '@tanstack/react-query';
import { logDynatraceError } from '@dx-ui/framework-dynatrace';
import { useLocation } from '@dx-ui/framework-location-provider';

type ICustomGroup = {
  setErrorState: React.Dispatch<React.SetStateAction<PageStatus>>;
};

type RatePlan = EventInfoRatePlans[number];
type RatePlanHotel = RatePlan['hotel'];

type IHotelShopAvail = RatePlanHotel & {
  ratePlanCode: string;
  shopAvail: NonNullable<GetShopAvailQuery['hotel']>['shopAvail'] | null;
};

type IRatePlans = Omit<RatePlan, 'hotel'> & {
  hotel?: RatePlanHotel | null;
  hotels?: IHotelShopAvail[];
};

const { publicRuntimeConfig } = getConfig();

const CustomGroup: React.FC<React.PropsWithChildren<ICustomGroup>> = ({ setErrorState }) => {
  const { safeQueryParams, router } = useWrappedRouter();
  const { setPageMetrics } = usePageMetrics();
  const { t } = useTranslation();
  const language = router.locale || 'en';
  const eventId = (safeQueryParams?.event as string) || '';
  const [ratePlans, setRatePlans] = useState<IRatePlans[]>([]);
  const { isAuthenticated, isCorpUser, logout } = useAuth();
  const client = useQueryClient();
  const [loadingRatePlans, setLoadingRatePlans] = useState(false);
  const { country } = useLocation();
  const {
    data,
    error,
    isSuccess,
    isFetching: loading,
    refetch,
  } = useGetEventPageQuery(
    {
      language,
      path: `https://my-event.hilton.com/en/attend-my-event/${eventId}`,
      input: {
        documentState: 'published',
      },
    },
    { enabled: !((!eventId || !language) && !isCorpUser) }
  );

  React.useEffect(() => {
    const onSuccessData = (eventPageData: GetEventPageQuery) => {
      if (eventPageData?.eventPage?.info?.ratePlans?.length) {
        const ratePlanData = cloneDeep(eventPageData?.eventPage?.info?.ratePlans || []);
        const spaBatch = ratePlanData
          .filter((rp) => rp.arrivalDate && rp.departureDate)
          .map((ratePlan) =>
            spaRequest(
              language,
              ratePlan.ctyhocn,
              ratePlan.arrivalDate as string,
              ratePlan.departureDate as string,
              1,
              {
                groupCode: ratePlan.ratePlanCode,
              },
              client,
              country
            )
          );
        setLoadingRatePlans(true);
        Promise.allSettled(spaBatch)
          .then((results) => {
            const unqRatePlans: IRatePlans[] = orderBy(
              uniqWith(
                ratePlanData,
                (ratePlan1, ratePlan2) =>
                  ratePlan1.arrivalDateFmt === ratePlan2.arrivalDateFmt &&
                  ratePlan1.departureDateFmt === ratePlan2.departureDateFmt &&
                  ratePlan1.cutOffDateFmt === ratePlan2.cutOffDateFmt
              ),
              'arrivalDate',
              'asc'
            );

            unqRatePlans.forEach((ratePlan) => {
              const hotels: IHotelShopAvail[] = [];
              const eventRatePlans = filter(ratePlanData, {
                cutOffDateFmt: ratePlan.cutOffDateFmt,
                arrivalDateFmt: ratePlan.arrivalDateFmt,
                departureDateFmt: ratePlan.departureDateFmt,
              });
              eventRatePlans.forEach((erp) => {
                const shopAvailData = results.find(
                  (result) =>
                    result.status === 'fulfilled' &&
                    erp.arrivalDate === result?.value?.hotel?.shopAvail?.arrivalDate &&
                    erp.departureDate === result?.value?.hotel?.shopAvail?.departureDate &&
                    erp.ctyhocn === result?.value?.hotel?.shopAvail?.ctyhocn &&
                    result?.value?.hotel?.shopAvail?.roomRates?.length &&
                    result?.value?.hotel?.shopAvail?.roomRates?.some(
                      (rate) =>
                        rate.ratePlanCode === erp.ratePlanCode ||
                        rate?.ratePlanCode?.includes(erp?.ratePlanCode || '')
                    )
                );
                if (erp.hotel) {
                  hotels.push({
                    ...erp.hotel,
                    ratePlanCode: erp.ratePlanCode || '',
                    shopAvail:
                      (shopAvailData as PromiseFulfilledResult<GetShopAvailQuery>)?.value?.hotel
                        ?.shopAvail || null,
                  });
                }
              });
              ratePlan.hotels = orderBy(hotels, 'name', 'asc');
              delete ratePlan['hotel'];
            });

            setRatePlans(unqRatePlans);
          })
          .catch((error: string | Error | Record<string, unknown>) =>
            logDynatraceError('CUSTOM_GROUP', error, 'failed to fetch shopAvail data')
          );
        setLoadingRatePlans(false);
      }
    };

    if (isSuccess) {
      onSuccessData(data);
    }
  }, [isSuccess, data, client, language, country]);

  React.useEffect(() => {
    if (ratePlans.length) {
      const groupRatePlans = (data?.eventPage?.info?.ratePlans || []).map((ratePlan) => ({
        ctyhocn: ratePlan.ctyhocn,
        groupCode: ratePlan.ratePlanCode || '',
      }));
      const commonData = { brandCode: 'HH', lang: language };
      const pageData = {
        brandName: 'HiltonHonors',
        lang: language,
        ratePlans: groupRatePlans,
      };
      setPageMetrics(PageName.CUSTOM_PAGE, commonData, pageData);
    }
  }, [data, language, ratePlans.length, setPageMetrics]);

  React.useEffect(() => {
    if (isAuthenticated && isCorpUser) {
      logout()
        .then(() => refetch())
        .catch(() => refetch());
    }
  }, [isAuthenticated, isCorpUser, logout, refetch]);

  React.useEffect(() => {
    if (!loading && (error || !data?.eventPage?.info?.ratePlans?.length)) {
      setErrorState(PageStatus.ERROR);
    }
  }, [data?.eventPage?.info?.ratePlans?.length, error, loading, setErrorState]);

  if (loading) {
    return (
      <div className="my-5">
        <InlineLoader />
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>{data?.eventPage?.content?.title}</title>
      </Head>
      <div className="mb-8">
        <PreviewHeadline title={data?.eventPage?.content?.title || ''} />
        <PreviewBody
          isPreviewView={false}
          description={data?.eventPage?.content?.description || ''}
          images={data?.eventPage?.content?.images || []}
        />
        {loadingRatePlans ? (
          <div className="mx-auto mt-20">
            <InlineLoader />
          </div>
        ) : (
          ratePlans.length > 0 &&
          ratePlans.map((ratePlan, rpIndex) => (
            <div className="hotel-info-wrapper" key={`hotelInfoWrapper_${ratePlan.ctyhocn}`}>
              <PreviewHeadline
                title={t('buttonsAndLinks.bookYourRoom')}
                titleType="subheading"
                showTitle={rpIndex === 0}
                dates={{
                  arrivalDateFmt: ratePlan.arrivalDateFmt || '',
                  departureDateFmt: ratePlan.departureDateFmt || '',
                  cutOffDateFmt: ratePlan.cutOffDateFmt || '',
                }}
              />
              {ratePlan.hotels?.length &&
                ratePlan.hotels?.map((hotel) => {
                  const shopAvailStatusCode = hotel?.shopAvail?.statusCode || 0;
                  const isNoAvailError =
                    [1000, 1740].includes(shopAvailStatusCode) ||
                    !hotel?.shopAvail ||
                    hotel?.shopAvail?.roomRates.length === 0;
                  const arrivalDate = ratePlan?.arrivalDate
                    ? parseISO(ratePlan?.arrivalDate)
                    : null;
                  const departureDate = ratePlan?.departureDate
                    ? parseISO(ratePlan?.departureDate)
                    : null;
                  const lengthOfStay =
                    departureDate && arrivalDate ? differenceInDays(departureDate, arrivalDate) : 0;
                  const isAvailBellCurveError = lengthOfStay > 1 && isNoAvailError;
                  const bookURL = `${bookUriBuilder({
                    baseUrl: `https://${publicRuntimeConfig.HONORS_HOST}`,
                    locale: language as MapLanguage,
                    urlParams: {
                      ctyhocn: hotel?.id,
                      rates: {
                        groupCode: hotel?.ratePlanCode,
                      },
                      additionalQS: {
                        fromId: 'HILTONLINKDIRECT',
                      },
                      ...(arrivalDate && {
                        dates: {
                          arrivalDate,
                          departureDate: isAvailBellCurveError
                            ? addDays(arrivalDate, 1)
                            : departureDate,
                        },
                      }),
                    },
                    page: isAvailBellCurveError ? 'flexibledates' : 'rooms',
                  })}&cid=OH,MB,attendmyevent,MULTIPR,OfferCTA,Multipage,Book,i81912`;
                  return (
                    <React.Fragment key={`hotelInfoDetailsDiv_${ratePlan.ctyhocn}`}>
                      <div
                        className="hotel-info-details flex flex-col pb-4 md:flex-row"
                        key={`hotelInfoDetails_${ratePlan.ctyhocn}`}
                      >
                        <HotelInfo
                          isPreviewView={false}
                          isNoAvailError={isNoAvailError}
                          isAvailBellCurveError={isAvailBellCurveError}
                          hotelData={{ hotel } as IHotel}
                          bookURL={bookURL}
                        />
                        <RoomsRates
                          roomRates={hotel?.shopAvail?.roomRates}
                          isNoAvailError={isNoAvailError}
                          isAvailBellCurveError={isAvailBellCurveError}
                          bookURL={bookURL}
                        />
                      </div>
                      <ResortFeeDetails
                        key={`ResortFeeDetails_${ratePlan.ctyhocn}`}
                        resortFeeText={hotel?.overview?.resortFeeDisclosureDesc || ''}
                      />
                    </React.Fragment>
                  );
                })}
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default CustomGroup;
