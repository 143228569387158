export const breakpoints: Record<string, string> = {
  sm: '640',
  md: '768',
  lg: '1024',
  xl: '1280',
  xxl: '1440',
};

const getBreakpoint = (size: keyof typeof breakpoints, asNumber?: boolean): number | string => {
  const value = breakpoints[size] || '0';
  return asNumber ? parseInt(value) : value;
};

export default getBreakpoint;
