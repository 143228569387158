import * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { usePageMetrics, PageName } from '../../hooks';
import { useMediaQuery } from 'usehooks-ts';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import getBreakpoint from '../../utils/getBreakpoint';
import { useRouter } from 'next/router';

type IErrorText = {
  heading: string;
  subHeading: string;
  phone: string;
};

type IContactInfo = {
  title: string;
  showIcon: boolean;
  numbers: {
    label: string;
    labelAccessibilityText: string;
    phone: string;
    'data-testid': string;
  }[];
};

type IErrorDisplayProps = {
  statusCode?: '404' | '500' | 'unknown' | 'technical';
};

const ErrorDisplay: React.FC<React.PropsWithChildren<IErrorDisplayProps>> = (
  props: IErrorDisplayProps
): React.ReactElement => {
  const { t, ready } = useTranslation();
  const { setPageMetrics } = usePageMetrics();
  const router = useRouter();
  const language = router.locale;
  const statusCode = props.statusCode || 'unknown';
  const contactInfo = t('textContent.contactInfo') as unknown as IContactInfo;
  const errorText =
    t(`textContent.errorPageText.${statusCode}`) || t('textContent.errorPageText.unknown');
  const isTabletOrLarger = useMediaQuery(`(min-width: ${getBreakpoint('md')}px)`);
  // 404, unknown or technical
  const { heading, subHeading, phone } = errorText as unknown as IErrorText;

  React.useEffect(() => {
    const commonData = { brandCode: 'HH', lang: language as string };
    const pageData = {
      errorCode: statusCode,
      errorMessage: `${heading} ${subHeading?.split('. ')[0]}.`,
    };
    if (ready) {
      setPageMetrics(PageName.ERROR_PAGE, commonData, pageData);
    }
  }, [heading, language, statusCode, setPageMetrics, subHeading, ready]);

  return (
    <div className="box-border flex min-h-screen flex-col flex-nowrap items-start justify-start px-7 py-14 md:items-center md:justify-center md:p-20">
      <h1
        className={cx(
          'font-headline text-primary mb-6 w-full text-left text-2xl font-black uppercase not-italic tracking-normal',
          {
            'text-larger7 mb-8 text-center': isTabletOrLarger,
          }
        )}
        aria-label={heading}
        data-testid="errorTitle"
      >
        {heading}
      </h1>
      <h2
        className="error-page-subheading text-color-gray mb-6 w-full text-left text-base font-bold not-italic md:mb-8 md:text-center md:text-2xl"
        data-testid="errorSubTitle"
      >
        {subHeading}
      </h2>
      <div className="mt-8 flex w-full flex-col flex-nowrap items-center justify-center">
        <ContactInfo phone={phone} justifyContent="center" {...contactInfo} />
      </div>
    </div>
  );
};

export default ErrorDisplay;
